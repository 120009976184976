import React, { useState, useEffect } from 'react';
import CotizadorService from '../../services/CotizadorService';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import EditarModal from '../../components/modals/EditarModal';
import EliminarModal from '../../components/modals/EliminarModal';

const CotizadorAdmin = () => {
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [newBrand, setNewBrand] = useState('');
    const [newModel, setNewModel] = useState({ name: '', oilLiters: '', oilFilterPrice: '', airFilterPrice: '', coolantPrice: '' });
    const [selectedBrandId, setSelectedBrandId] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);

    useEffect(() => {
        fetchBrands();
    }, []);

    const fetchBrands = async () => {
        try {
            const data = await CotizadorService.getBrands();
            setBrands(data);
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    const fetchModelsByBrand = async (brandId) => {
        try {
            const data = await CotizadorService.getModelsByBrandId(brandId);
            setModels(data);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    const handleBrandSelect = async (event) => {
        const brandId = event.target.value;
        setSelectedBrandId(brandId);
        await fetchModelsByBrand(brandId);
    };

    const handleAddBrand = async () => {
        try {
            await CotizadorService.addBrand(newBrand);
            fetchBrands();
            setNewBrand('');
        } catch (error) {
            console.error('Error adding brand:', error);
        }
    };

    const handleAddModel = async () => {
        try {
            await CotizadorService.addModel(selectedBrandId, newModel);
            fetchModelsByBrand(selectedBrandId);
            setNewModel({ name: '', oilLiters: '', oilFilterPrice: '', airFilterPrice: '', coolantPrice: '' });
        } catch (error) {
            console.error('Error adding model:', error);
        }
    };

    const handleUpdateBrand = async (brandId, updatedName) => {
        try {
            await CotizadorService.updateBrand(brandId, { name: updatedName });
            fetchBrands();
        } catch (error) {
            console.error(`Error updating brand ${brandId}:`, error);
        }
    };

    const handleDeleteBrand = async (brandId) => {
        try {
            await CotizadorService.deleteBrand(brandId);
            fetchBrands();
        } catch (error) {
            console.error(`Error deleting brand ${brandId}:`, error);
        }
    };

    const handleUpdateModel = async (modelId, updatedData) => {
        try {
            await CotizadorService.updateModel(modelId, updatedData);
            fetchModelsByBrand(selectedBrandId);
        } catch (error) {
            console.error(`Error updating model ${modelId}:`, error);
        }
    };

    const handleDeleteModel = async (modelId) => {
        try {
            await CotizadorService.deleteModel(modelId);
            fetchModelsByBrand(selectedBrandId);
        } catch (error) {
            console.error(`Error deleting model ${modelId}:`, error);
        }
    };

    const handleEditClick = (item) => {
        setEditItem({
            _id: item._id,
            name: item.name,
            oilLiters: item.oilLiters,
            oilFilterPrice: item.oilFilterPrice,
            airFilterPrice: item.airFilterPrice,
            coolantPrice: item.coolantPrice,
            type: item.type,
        });
        setShowEditModal(true);
    };

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
        setShowDeleteModal(true);
    };

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4">
            <div className="container mx-auto max-w-4xl bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Administración de Marcas y Modelos</h1>

                {/* Formulario para agregar marcas */}
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">Agregar Nueva Marca</h2>
                    <div className="flex items-center">
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Nombre de la marca"
                            value={newBrand}
                            onChange={(e) => setNewBrand(e.target.value)}
                        />
                        <button
                            type="button"
                            className="ml-2 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                            onClick={handleAddBrand}
                        >
                            Agregar Marca
                        </button>
                    </div>
                </div>

                {/* Tabla de Marcas */}
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">Marcas</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead>
                            <tr className="bg-gray-100">
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Marca</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Editar</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Eliminar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {brands.map((brand) => (
                                <tr key={brand._id}>
                                    <td className="py-2 px-4 border-b border-gray-300">{brand.name}</td>
                                    <td className="py-2 px-4 border-b border-gray-300">
                                        <button
                                            className="text-blue-500 hover:text-blue-700"
                                            onClick={() => handleEditClick({ _id: brand._id, name: brand.name, type: 'brand' })}
                                        >
                                            <FiEdit />
                                        </button>
                                    </td>
                                    <td className="py-2 px-4 border-b border-gray-300">
                                        <button
                                            className="text-red-500 hover:text-red-700"
                                            onClick={() => handleDeleteClick({ _id: brand._id, name: brand.name, type: 'brand' })}
                                        >
                                            <FiTrash2 />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Formulario para agregar modelos */}
                <div>
                    <h2 className="text-2xl font-bold mb-4">Agregar Nuevo Modelo</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="brandSelect" className="block text-sm font-medium text-gray-700">
                                Selecciona una marca
                            </label>
                            <select
                                id="brandSelect"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                value={selectedBrandId}
                                onChange={handleBrandSelect}
                            >
                                <option value="">Selecciona una marca</option>
                                {brands.map((brand) => (
                                    <option key={brand._id} value={brand._id}>{brand.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="newModelName" className="block text-sm font-medium text-gray-700">
                                Nombre del modelo
                            </label>
                            <input
                                type="text"
                                id="newModelName"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Nombre del modelo"
                                value={newModel.name}
                                onChange={(e) => setNewModel({ ...newModel, name: e.target.value })}
                            />
                            <label htmlFor="oilLiters" className="block text-sm font-medium text-gray-700 mt-2">
                                Litros de aceite
                            </label>
                            <input
                                type="number"
                                id="oilLiters"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Litros de aceite"
                                value={newModel.oilLiters}
                                onChange={(e) => setNewModel({ ...newModel, oilLiters: e.target.value })}
                            />
                            <label htmlFor="oilFilterPrice" className="block text-sm font-medium text-gray-700 mt-2">
                                Precio del filtro de aceite
                            </label>
                            <input
                                type="number"
                                id="oilFilterPrice"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Precio del filtro de aceite"
                                value={newModel.oilFilterPrice}
                                onChange={(e) => setNewModel({ ...newModel, oilFilterPrice: e.target.value })}
                            />
                            <label htmlFor="airFilterPrice" className="block text-sm font-medium text-gray-700 mt-2">
                                Precio del filtro de aire
                            </label>
                            <input
                                type="number"
                                id="airFilterPrice"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Precio del filtro de aire"
                                value={newModel.airFilterPrice}
                                onChange={(e) => setNewModel({ ...newModel, airFilterPrice: e.target.value })}
                            />
                            <label htmlFor="coolantPrice" className="block text-sm font-medium text-gray-700 mt-2">
                                Precio del refrigerante
                            </label>
                            <input
                                type="number"
                                id="coolantPrice"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Precio del refrigerante"
                                value={newModel.coolantPrice}
                                onChange={(e) => setNewModel({ ...newModel, coolantPrice: e.target.value })}
                            />
                            <button
                                type="button"
                                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                                onClick={handleAddModel}
                            >
                                Agregar Modelo
                            </button>
                        </div>
                    </div>
                </div>

                {/* Tabla de Modelos */}
                <div className="mt-8">
                    <h2 className="text-2xl font-bold mb-4">Modelos</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead>
                            <tr className="bg-gray-100">
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Nombre</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Litros de Aceite</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Precio Filtro Aceite</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Precio Filtro Aire</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Precio Refrigerante</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Editar</th>
                                <th className="py-2 px-4 border-b border-gray-300 text-left text-sm font-bold text-gray-600 uppercase">Eliminar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {models.map((model) => (
                                <tr key={model._id}>
                                    <td className="py-2 px-4 border-b border-gray-300">{model.name}</td>
                                    <td className="py-2 px-4 border-b border-gray-300">{model.oilLiters}</td>
                                    <td className="py-2 px-4 border-b border-gray-300">${model.oilFilterPrice.toFixed(2)}</td>
                                    <td className="py-2 px-4 border-b border-gray-300">${model.airFilterPrice.toFixed(2)}</td>
                                    <td className="py-2 px-4 border-b border-gray-300">${model.coolantPrice.toFixed(2)}</td>
                                    <td className="py-2 px-4 border-b border-gray-300">
                                        <button
                                            className="text-blue-500 hover:text-blue-700"
                                            onClick={() => handleEditClick(model)}
                                        >
                                            <FiEdit />
                                        </button>
                                    </td>
                                    <td className="py-2 px-4 border-b border-gray-300">
                                        <button
                                            className="text-red-500 hover:text-red-700"
                                            onClick={() => handleDeleteClick(model)}
                                        >
                                            <FiTrash2 />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {showEditModal && (
                <EditarModal
                    item={editItem}
                    onClose={() => setShowEditModal(false)}
                    onSave={handleUpdateModel}
                />
            )}

            {showDeleteModal && (
                <EliminarModal
                    item={deleteItem}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => {
                        handleDeleteModel(deleteItem._id);
                        setShowDeleteModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default CotizadorAdmin;
