import React, { useState, useEffect } from 'react';
import CotizadorService from "../../services/CotizadorService";
import GeneratePDF from "./GeneratePDF";

const CotizadorMaster = () => {
    const [cliente, setCliente] = useState('');
    const [nit, setNit] = useState('');
    const [asesor, setAsesor] = useState('Diego');
    const [celular, setCelular] = useState('');
    const [marca, setMarca] = useState('');
    const [linea, setLinea] = useState('');
    const [modelo, setModelo] = useState('');
    const [motor, setMotor] = useState('');
    const [marcas, setMarcas] = useState([]);
    const [lineas, setLineas] = useState({});
    const modelos = Array.from({ length: 36 }, (_, i) => 1990 + i);
    const [motores, setMotores] = useState(['1.2', '1.4', '1.5', '1.6', '1.8', '2.0', '2.5', '3.0']);

    const servicios = [
        'Servicio Mayor',
        'Servicio Menor',
        'Revisión de Frenos',
        'Nivelación de líquidos',
        'Lavado de motor',
        'Revisión de batería',
        'Revisión de tren delantero'
    ];

    const [selectedServices, setSelectedServices] = useState([]);
    const [showMayor, setShowMayor] = useState(false);
    const [showMenor, setShowMenor] = useState(false);
    const [aceite, setAceite] = useState('');
    const [precioAceite, setPrecioAceite] = useState(''); // Este es el precio del aceite
    const [precioFiltroAire, setPrecioFiltroAire] = useState('');
    const [precioFiltroAceite, setPrecioFiltroAceite] = useState('');
    const [precioCandelas, setPrecioCandelas] = useState('');
    const [manoObra, setManoObra] = useState(150);  // Este es el monto de mano de obra, se puede cambiar desde el admin

    const aceites = [
        "Castrol Semi-Sintetico 20w50",
        "Castrol Full-Sintetico 10w30",
        "Castrol Full-Sintetico 5w30"
    ];

    useEffect(() => {
        const pdfHistory = JSON.parse(localStorage.getItem('pdfHistory'));
        console.log(pdfHistory);
        setShowMayor(selectedServices.includes('Servicio Mayor'));
        setShowMenor(selectedServices.includes('Servicio Menor'));
    }, [selectedServices]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const data = await CotizadorService.getBrands();
                setMarcas(data);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };
        fetchBrands();
    }, []);

    useEffect(() => {
        const fetchModelsByBrand = async () => {
            if (marca) {
                try {
                    const data = await CotizadorService.getModelsByBrandId(marca);
                    setLineas(prev => ({ ...prev, [marca]: data }));
                } catch (error) {
                    console.error(`Error fetching models for brand ${marca}:`, error);
                }
            }
        };
        fetchModelsByBrand();
    }, [marca]);

    const handleServiceSelection = (service) => {
        setSelectedServices(prev =>
            prev.includes(service) ? prev.filter(s => s !== service) : [...prev, service]
        );
    };

    const generatePDF = () => {
        const selectedBrand = marcas.find(m => m._id === marca)?.name || '';
        const selectedModel = lineas[marca]?.find(l => l._id === linea)?.name || '';

        // Crear los ítems del servicio mayor
        const mayorServices = [];
        const menorServices = [];
        const extrasServices = [];

        selectedServices.forEach(service => {
            if (service === 'Servicio Mayor') {
                mayorServices.push([
                    `Cambio de aceite (${aceite})`,
                    '1',
                    `Q.${precioAceite}`,  // Mostrar el precio del aceite
                    `Q.${precioAceite}`   // Mostrar el precio del aceite
                ]);
            } else if (service === 'Servicio Menor') {
                menorServices.push([
                    `FILTRO DE AIRE (${precioFiltroAire})`,
                    '1',
                    `Q.${precioFiltroAire}`,
                    `Q.${precioFiltroAire}`
                ]);
            } else {
                extrasServices.push([service, '1', '-', '-']);
            }
        });

        // Si el servicio mayor está marcado, agregar los filtros y las candelas
        if (showMayor) {
            mayorServices.push(
                [`Filtro de aire`, '1', `Q.${precioFiltroAire}`, `Q.${precioFiltroAire}`],
                [`Filtro de aceite`, '1', `Q.${precioFiltroAceite}`, `Q.${precioFiltroAceite}`],
                [`Cambio de candelas `, '1', `Q.${precioCandelas}`, `Q.${precioCandelas}`]
            );
        }

        // Si el servicio menor está marcado, agregar los filtros
        if (showMenor) {
            menorServices.push(
                [`Filtro de aire`, '1', `Q.${precioFiltroAire}`, `Q.${precioFiltroAire}`],
                [`Filtro de aceite`, '1', `Q.${precioFiltroAceite}`, `Q.${precioFiltroAceite}`]
            );
        }

        // Enviar los datos al generador de PDF
        GeneratePDF.generate({
            cliente,
            nit,
            asesor,
            celular,
            marca: selectedBrand,
            linea: selectedModel,
            modelo,
            motor,
            mayorServices,
            menorServices,
            extrasServices,
            manoObra,
            total: '0.00' // Asegúrate de sumar todo y calcular el total correctamente.
        });
    };

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4">
            <div className="container mx-auto max-w-4xl bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-3xl font-bold text-blue-900 mb-6 text-center">Cotizador Master</h1>
                <div className="grid grid-cols-2 gap-4">
                    <input className="border p-2 rounded" placeholder="Nombre del Cliente" value={cliente} onChange={e => setCliente(e.target.value)} />
                    <input className="border p-2 rounded" placeholder="NIT" value={nit} onChange={e => setNit(e.target.value)} />
                    <select className="border p-2 rounded" value={asesor} onChange={e => setAsesor(e.target.value)}>
                        <option value="Diego">Diego</option>
                        <option value="Annie">Annie</option>
                    </select>
                    <input className="border p-2 rounded" placeholder="Celular" value={celular} onChange={e => setCelular(e.target.value)} />
                </div>

                <div className="mt-6 grid grid-cols-2 gap-4">
                    <select className="border p-2 rounded" value={marca} onChange={e => setMarca(e.target.value)}>
                        <option value="">Selecciona una marca</option>
                        {marcas.map(m => <option key={m._id} value={m._id}>{m.name}</option>)}
                    </select>
                    <select className="border p-2 rounded" value={linea} onChange={e => setLinea(e.target.value)}>
                        <option value="">Selecciona una línea</option>
                        {lineas[marca] && lineas[marca].map(l => <option key={l._id} value={l._id}>{l.name}</option>)}
                    </select>
                    <select className="border p-2 rounded" value={modelo} onChange={e => setModelo(e.target.value)}>
                        <option value="">Selecciona un modelo</option>
                        {modelos.map((modelo, index) => (
                            <option key={index} value={modelo}>{modelo}</option>
                        ))}
                    </select>
                    <select className="border p-2 rounded" value={motor} onChange={e => setMotor(e.target.value)}>
                        <option value="">Selecciona un motor</option>
                        {motores.map((motor, index) => (
                            <option key={index} value={motor}>{motor}</option>
                        ))}
                    </select>
                </div>

                <div className="mt-6">
                    <h2 className="text-lg font-bold mb-2">Seleccionar Servicios</h2>
                    <div className="grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
                        {servicios.map(service => (
                            <label key={service} className="flex items-center">
                                <input type="checkbox" checked={selectedServices.includes(service)} onChange={() => handleServiceSelection(service)} />
                                <span className="ml-2">{service}</span>
                            </label>
                        ))}
                    </div>
                </div>

                {showMayor && (
                    <div className="mt-4 p-4 bg-gray-200 rounded">
                        <h3 className="text-lg font-bold mb-2">Detalles de Servicio Mayor</h3>
                        <select className="border p-2 rounded w-full" value={aceite} onChange={e => setAceite(e.target.value)}>
                            <option value="">Selecciona el Aceite</option>
                            {aceites.map((oil, index) => <option key={index} value={oil}>{oil}</option>)}
                        </select>
                        {/* Aquí agregamos el precio del aceite */}
                        <input
                            className="border p-2 rounded w-full mt-2"
                            placeholder="Precio del Aceite"
                            value={precioAceite}
                            onChange={e => setPrecioAceite(e.target.value)}
                        />
                        <input
                            className="border p-2 rounded w-full mt-2"
                            placeholder="Precio Filtro Aire"
                            value={precioFiltroAire}
                            onChange={e => setPrecioFiltroAire(e.target.value)}
                        />
                        <input
                            className="border p-2 rounded w-full mt-2"
                            placeholder="Precio Filtro Aceite"
                            value={precioFiltroAceite}
                            onChange={e => setPrecioFiltroAceite(e.target.value)}
                        />
                        <input
                            className="border p-2 rounded w-full mt-2"
                            placeholder="Precio Candelas"
                            value={precioCandelas}
                            onChange={e => setPrecioCandelas(e.target.value)}
                        />
                    </div>
                )}

                {showMenor && (
                    <div className="mt-4 p-4 bg-gray-200 rounded">
                        <h3 className="text-lg font-bold mb-2">Detalles de Servicio Menor</h3>
                        <select className="border p-2 rounded w-full" value={aceite} onChange={e => setAceite(e.target.value)}>
                            <option value="">Selecciona el Aceite</option>
                            {aceites.map((oil, index) => <option key={index} value={oil}>{oil}</option>)}
                        </select>
                        {/* Aquí agregamos el precio del aceite */}
                        <input
                            className="border p-2 rounded w-full mt-2"
                            placeholder="Precio del Aceite"
                            value={precioAceite}
                            onChange={e => setPrecioAceite(e.target.value)}
                        />
                        <input
                            className="border p-2 rounded w-full mt-2"
                            placeholder="Precio Filtro Aire"
                            value={precioFiltroAire}
                            onChange={e => setPrecioFiltroAire(e.target.value)}
                        />
                        <input
                            className="border p-2 rounded w-full mt-2"
                            placeholder="Precio Filtro Aceite"
                            value={precioFiltroAceite}
                            onChange={e => setPrecioFiltroAceite(e.target.value)}
                        />
                    </div>
                )}
            </div>

            <div className="text-center mt-6">
                <button className="bg-blue-700 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-800 transition" onClick={generatePDF}>Generar PDF</button>
            </div>
        </div>
    );
};

export default CotizadorMaster;
