import axios from 'axios';
import {BASE_URL} from "../utils/Constants";

const ParkingService = {
    getAllParkingLots: async () => {
        try {
            const response = await axios.get(`${BASE_URL}/parking-lots`);
            return response.data;
        } catch (error) {
            console.error('Error fetching parking lots:', error);
            throw error;
        }
    },

    getParkingLotById: async (id) => {
        try {
            const response = await axios.get(`${BASE_URL}/parking-lots/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching parking lot with ID ${id}:`, error);
            throw error;
        }
    },

    createParkingLot: async (data) => {
        try {
            const response = await axios.post(`${BASE_URL}/parking-lots`, {data});
            return response.data;
        } catch (error) {
            console.error('Error creating parking lot:', error);
            throw error;
        }
    },

    updateParkingLot: async (id, data) => {
        try {
            const response = await axios.put(`${BASE_URL}/parking-lots/${id}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating parking lot with ID ${id}:`, error);
            throw error;
        }
    },

    deleteParkingLot: async (id) => {
        try {
            const response = await axios.delete(`${BASE_URL}/parking-lots/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Error deleting parking lot with ID ${id}:`, error);
            throw error;
        }
    },
};

export default ParkingService;
