import React from 'react';
import WhatsappComponent from '../components/tools/WhatsappComponent';
import ScrollToTop from "../components/tools/ScrollToTop";

const About = () => {
    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4">
            <div className="container mx-auto max-w-4xl bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">¿Quiénes somos?</h1>
                <p className="text-gray-700 mb-4">
                    Somos una empresa dedicada a los servicios preventivos de vehículos a domicilio, ofreciendo comodidad y calidad a nuestros clientes para mantener sus vehículos en óptimas condiciones. Con años de experiencia en el sector automotriz, nuestro equipo de profesionales está comprometido con la excelencia y la satisfacción del cliente.
                </p>
                <p className="text-gray-700 mb-4">
                    Nuestros servicios incluyen mantenimiento mayor y menor, cambio de aceite, cambio de pastillas de freno, y muchos más. Utilizamos herramientas y productos de alta calidad para garantizar que tu vehículo reciba el mejor cuidado posible, sin la necesidad de salir de casa.
                </p>
                <p className="text-gray-700 mb-4">
                    Nos esforzamos por ser una empresa confiable y accesible, con precios competitivos y un servicio al cliente excepcional. Creemos en la importancia del mantenimiento preventivo para prolongar la vida útil de tu vehículo y evitar reparaciones costosas a largo plazo.
                </p>
                <p className="text-gray-700 mb-4">
                    Nuestra misión es facilitar el mantenimiento de tu vehículo mediante servicios convenientes y de alta calidad, directamente en la puerta de tu casa. Estamos aquí para ayudarte a mantener tu vehículo en las mejores condiciones posibles, brindándote tranquilidad y confianza en cada kilómetro que recorras.
                </p>
                <p className="text-gray-700 mb-4">
                    <strong>¿Por qué elegirnos?</strong> Nos respaldan años de experiencia y el compromiso de nuestro fundador de la empresa y asociados, quienes comparten la visión de ofrecer servicios de alta calidad y conveniencia.
                </p>
                <p className="text-gray-700 mb-4">
                    Si deseas saber más sobre nuestros servicios, no dudes en <a href="/contact" className="text-blue-500 hover:underline">contactarnos</a> o explorar nuestro sitio web para más información. ¡Estamos aquí para servirte!
                </p>
            </div>
            <ScrollToTop />
            {/* Botón flotante de WhatsApp */}
            <WhatsappComponent />
        </div>
    );
};

export default About;
