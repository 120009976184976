import React from 'react';
import { useNavigate } from 'react-router-dom';

const ADM = () => {
    const navigate = useNavigate();

    const handleIrClick = (url) => {
        navigate(url);
    };

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4 flex flex-col items-center">
            <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Administrador</h1>
            <div className="space-y-4">
                <button
                    onClick={() => handleIrClick('/quote/admin')}
                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 w-full max-w-xs"
                >
                    Ir a Cotizador Admin
                </button>
                <button
                    onClick={() => handleIrClick('/admin/parkinglots')}
                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 w-full max-w-xs"
                >
                    Ir a Admin Parking Lots
                </button>
                {/* Puedes agregar más botones para otras rutas de admin aquí */}
            </div>
        </div>
    );
};


export default ADM;
