import axios from 'axios';
import {BASE_URL} from "../utils/Constants";

const LocationService = {
    getDepartments: async () => {
        const response = await axios.get(`${BASE_URL}/departments`);
        return response.data;
    },

    getMunicipalities: async (departmentId) => {
        const response = await axios.get(`${BASE_URL}/municipalities?departmentId=${departmentId}`);
        return response.data;
    },

    getZones: async (municipalityId) => {
        const response = await axios.get(`${BASE_URL}/zones?municipalityId=${municipalityId}`);
        return response.data;
    }
};

export default LocationService;
