import React, { useState, useEffect } from 'react';
import AgendarComponente from '../components/sections/AgendarComponente';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CotizadorService from '../services/CotizadorService';

const CotizadorForm = () => {
    const [marca, setMarca] = useState('');
    const [linea, setLinea] = useState('');
    const [modelo, setModelo] = useState('');
    const [motor, setMotor] = useState('');
    const [servicio, setServicio] = useState('');
    const [fecha, setFecha] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showAgendaModal, setShowAgendaModal] = useState(false);
    const [showDarkOverlay, setShowDarkOverlay] = useState(false);

    const [marcas, setMarcas] = useState([]);
    const [lineas, setLineas] = useState({});
    const modelos = Array.from({ length: 29 }, (_, i) => 1996 + i);
    const [motores, setMotores] = useState(['1.2', '1.4', '1.5', '1.6', '1.8', '2.0', '2.5', '3.0']);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const data = await CotizadorService.getBrands();
                setMarcas(data);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);

    useEffect(() => {
        const fetchModelsByBrand = async () => {
            if (marca) {
                try {
                    const data = await CotizadorService.getModelsByBrandId(marca);
                    setLineas({ ...lineas, [marca]: data });
                } catch (error) {
                    console.error(`Error fetching models for brand ${marca}:`, error);
                }
            }
        };

        fetchModelsByBrand();
    }, [marca]);

    const handleAppointmentChange = (date) => {
        // Simulamos la carga de citas existentes desde una API
        const fetchedAppointments = [
            new Date(2024, 5, 25, 10, 0), // Ejemplo de cita agendada el 25 de junio de 2024 a las 10:00 AM
            new Date(2024, 5, 25, 14, 0), // Ejemplo de cita agendada el 25 de junio de 2024 a las 2:00 PM
        ];
        setAppointments(fetchedAppointments);
    };

    const handleGeneratePDF = () => {
        const doc = new jsPDF();
        const logo = new Image();
        logo.src = '/logos/serbizcarlogo.png';

        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 50, 20);
            doc.setFontSize(18);
            doc.text('Cotización de Servicio', 105, 30, null, null, 'center');

            const marcaSeleccionada = marcas.find(m => m._id === marca)?.name || '';
            const lineaSeleccionada = lineas[marca]?.find(l => l._id === linea)?.name || '';

            // Construir los datos para la tabla
            const tableData = [
                ['Marca', marcaSeleccionada],
                ['Línea', lineaSeleccionada],
                ['Modelo', modelo],
                ['Motor', motor],
                ['Tipo de Servicio', servicio],
                ['Fecha Planeada', fecha ? fecha.toLocaleDateString() : ''],
                ['Costo', getCostoServicio(servicio)]
            ];

            doc.autoTable({
                startY: 50,
                head: [['Campo', 'Valor']],
                body: tableData,
            });

            doc.save('cotizacion.pdf');

            setShowAlertModal(true);
            setShowDarkOverlay(true);
        };
    };

    const getCostoServicio = (servicio) => {
        switch (servicio) {
            case 'Servicio menor':
                return 'Q700.00';
            case 'Servicio mayor':
                return 'Q1500';
            case 'Cambio de frenos':
                return 'Q500';
            default:
                return '';
        }
    };

    const handleAgendarCita = () => {
        setShowAlertModal(false);
        setShowAgendaModal(true);
        setShowDarkOverlay(false);
    };

    const handleCancelarAgenda = () => {
        setShowAgendaModal(false);
        setShowAlertModal(false);
        setShowDarkOverlay(false);
    };

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4">
            {showDarkOverlay && <div className="fixed inset-0 bg-black opacity-50 z-50"></div>}

            <div className="container mx-auto max-w-4xl bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Cotizador de Servicio personalizado</h1>
                <form className="space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="marca" className="block text-sm font-medium text-gray-700">Marca de tu vehículo</label>
                            <select id="marca" className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" value={marca} onChange={(e) => setMarca(e.target.value)}>
                                <option value="">Selecciona una marca</option>
                                {marcas.map((marca, index) => (
                                    <option key={index} value={marca._id}>{marca.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="linea" className="block text-sm font-medium text-gray-700">Línea de tu vehículo</label>
                            <select id="linea" className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" value={linea} onChange={(e) => setLinea(e.target.value)}>
                                <option value="">Selecciona una línea</option>
                                {lineas[marca] && lineas[marca].map((linea, index) => (
                                    <option key={index} value={linea._id}>{linea.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="modelo" className="block text-sm font-medium text-gray-700">Modelo de tu vehículo</label>
                            <select id="modelo" className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" value={modelo} onChange={(e) => setModelo(e.target.value)}>
                                <option value="">Selecciona un modelo</option>
                                {modelos.map((modelo, index) => (
                                    <option key={index} value={modelo}>{modelo}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="motor" className="block text-sm font-medium text-gray-700">Motor de tu vehículo</label>
                            <select id="motor" className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" value={motor} onChange={(e) => setMotor(e.target.value)}>
                                <option value="">Selecciona un motor</option>
                                {motores.map((motor, index) => (
                                    <option key={index} value={motor}>{motor}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="servicio" className="block text-sm font-medium text-gray-700">Tipo de servicio</label>
                            <select id="servicio" className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" value={servicio} onChange={(e) => setServicio(e.target.value)}>
                                <option value="">Selecciona un servicio</option>
                                <option value="Servicio menor">Servicio menor</option>
                                <option value="Servicio mayor">Servicio mayor</option>
                                <option value="Cambio de frenos">Cambio de frenos</option>
                            </select>
                        </div>
                    </div>

                    {showAlertModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center">
                            <div className="bg-white w-full max-w-md p-8 rounded-lg shadow-lg">
                                <h2 className="text-2xl font-bold mb-4">¿Deseas agendar una cita?</h2>
                                <div className="flex justify-center mt-6">
                                    <button
                                        type="button"
                                        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mr-4"
                                        onClick={handleAgendarCita}
                                    >
                                        Agendar
                                    </button>
                                    <button
                                        type="button"
                                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                                        onClick={handleCancelarAgenda}
                                    >
                                        No, gracias
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showAgendaModal && (
                        <AgendarComponente
                            appointments={appointments}
                            onClose={() => setShowAgendaModal(false)}
                            onAppointmentChange={handleAppointmentChange}
                        />
                    )}

                    <div className="text-center mt-6">
                        <button
                            type="button"
                            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                            onClick={handleGeneratePDF}
                        >
                            Generar cotización
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CotizadorForm;
