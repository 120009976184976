import React, { useState, useEffect } from 'react';
import CotizadorService from '../services/CotizadorService'; // Asegúrate de tener este servicio para manejar las APIs
import GeneratePDF from '../pages/admin/GeneratePDF'; // Asegúrate de importar la función para generar el PDF

const CotizadorForm = () => {
    const [step, setStep] = useState(1); // Paso actual
    const [marcas, setMarcas] = useState([]); // Lista de marcas
    const [lineas, setLineas] = useState([]); // Modelos por marca
    const [motores, setMotores] = useState(['1.2', '1.4', '1.5', '1.6', '1.8', '2.0', '2.5', '3.0']); // Lista de motores
    const [marca, setMarca] = useState('');
    const [linea, setLinea] = useState('');
    const [modelo, setModelo] = useState(''); // Año
    const [motor, setMotor] = useState('');
    const [kmMi, setKmMi] = useState('');
    const [unidadKmMi, setUnidadKmMi] = useState('KM');
    const [aceite, setAceite] = useState('');
    const [precioAceite, setPrecioAceite] = useState(''); // Precio de filtro de aire
    const [filtroAceite, setFiltroAceite] = useState(''); // Precio de aceite
    const [filtroAire, setFiltroAire] = useState(''); // Precio de filtro de aire
    const [nombre, setNombre] = useState('');
    const [numero, setNumero] = useState('');

    const servicios = [
        'Servicio Mayor',
        'Servicio Menor',
        'Revisión de Frenos',
        'Nivelación de líquidos',
        'Lavado de motor',
        'Revisión de batería',
        'Revisión de tren delantero'
    ];

    // Cargar marcas al inicio
    useEffect(() => {
        const fetchMarcas = async () => {
            try {
                const data = await CotizadorService.getBrands(); // Cambia a tu servicio para obtener las marcas
                setMarcas(data);
            } catch (error) {
                console.error("Error al cargar las marcas:", error);
            }
        };

        fetchMarcas();
    }, []);

    useEffect(() => {
        const fetchModelos = async () => {
            if (marca) {
                try {
                    const data = await CotizadorService.getModelsByBrandId(marca); // Cambia a tu servicio para obtener los modelos por marca
                    setLineas(data);
                } catch (error) {
                    console.error(`Error al cargar los modelos para la marca ${marca}:`, error);
                }
            }
        };

        fetchModelos();
    }, [marca]);

    // Lógica para determinar el precio del aceite y filtro de aire
    const obtenerPrecios = () => {
        let precioAceite = '';
        let precioFiltroAire = '60'; // Filtro de aire por defecto
        let precioFiltroAceite = '35'; // Filtro de aire por defecto

        if (kmMi > 150000 && modelo < 2004) {
            precioAceite = '350'; // Precio de aceite si km > 150,000 y año < 2004
        } else if (kmMi < 90000 && modelo > 2015) {
            precioAceite = '450'; // Precio de aceite si km < 90,000 y año > 2015
        } else if (kmMi >= 90000 && kmMi < 150000 && modelo > 2004 && modelo < 2015) {
            precioAceite = '430'; // Precio de aceite si km entre 90,000 y 149,999 y año entre 2004 y 2015
        } else {
            precioAceite = '400'; // Precio de aceite por defecto
        }

        setPrecioAceite(precioAceite);
        setFiltroAceite(precioFiltroAceite);
        setFiltroAire(precioFiltroAire); // Asignando precio de filtro de aire

        return { precioAceite, precioFiltroAceite, precioFiltroAire };
    };

    // Determina los servicios según la selección de "Servicio Mayor" o "Servicio Menor"
    const obtenerServicios = () => {
        let serviciosSeleccionados = [];
        if (servicios.includes('Servicio Mayor')) {
            // Si se selecciona "Servicio Mayor", agregamos todos los servicios, excepto "Servicio Menor"
            serviciosSeleccionados = servicios.filter(servicio => servicio !== 'Servicio Menor');
        } else if (servicios.includes('Servicio Menor')) {
            // Si se selecciona "Servicio Menor", agregamos solo los servicios específicos
            serviciosSeleccionados = servicios.filter(servicio => servicio === 'Servicio Menor');
        }
        return serviciosSeleccionados;
    };

    const handleGeneratePDF = () => {
        const selectedMarca = marcas.find(m => m._id === marca)?.name || '';
        const selectedServicios = obtenerServicios();
        const precios = obtenerPrecios(); // Obtener los precios de aceite y filtro de aire
        const menorServices = [];
        const extrasServices = [];

        // Combinamos los valores en un solo array
        const mayorServices = [
            // Precios de productos fijos
            [`Aceite`, '1', `Q.${precios.precioAceite}`, `Q.${precios.precioAceite}`],
            [`Filtro de aire`, '1', `Q.${precios.precioFiltroAire}`, `Q.${precios.precioFiltroAire}`],
            [`Filtro de aceite`, '1', `Q.${precios.precioFiltroAceite}`, `Q.${precios.precioFiltroAceite}`],
            // Agregar los servicios seleccionados
            ...selectedServicios.map(servicio => {
                return [servicio, '1', `-`, `-`]; // Asumí un precio de Q.100 por servicio, ajusta según lo necesario
            })
        ];

        // Llamamos a la función para generar el PDF con los datos de la cotización
        GeneratePDF.generate({
            cliente: nombre,
            asesor: 'Diego',
            celular: numero,
            marca: selectedMarca,
            linea,
            modelo,
            motor,
            mayorServices,
            menorServices,
            extrasServices, // Pasamos el array combinado
            total: 500 // Aquí puedes agregar el total calculado según los servicios seleccionados
        });
    };

    const handleNextStep = () => {
        setStep(2);
    };

    const handlePreviousStep = () => {
        setStep(1);
    };

    const modelosDisponibles = Array.from({ length: 29 }, (_, i) => 1996 + i); // Modelo es el año

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4 flex justify-center items-center">
            <div className="max-w-4xl w-full">
                {/* Primera tarjeta: Coticemos */}
                {step === 1 && (
                    <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 ease-in-out duration-300">
                        <h2 className="text-3xl font-semibold text-center text-blue-900 mb-6">Coticemos</h2>
                        <p className="text-gray-700 text-lg mb-4 text-center">Ingresa los datos de tu vehículo como primer paso</p>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {/* Marca */}
                            <div>
                                <label htmlFor="marca" className="block text-sm font-medium text-gray-700">Marca de tu vehículo</label>
                                <select
                                    id="marca"
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    value={marca}
                                    onChange={(e) => setMarca(e.target.value)}
                                >
                                    <option value="">Selecciona una marca</option>
                                    {marcas.map(m => <option key={m._id} value={m._id}>{m.name}</option>)}
                                </select>
                            </div>

                            {/* Línea */}
                            <div>
                                <label htmlFor="linea" className="block text-sm font-medium text-gray-700">Línea de tu vehículo</label>
                                <select
                                    id="linea"
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    value={linea}
                                    onChange={(e) => setLinea(e.target.value)}
                                >
                                    <option value="">Selecciona una línea</option>
                                    {lineas.map((linea) => (
                                        <option key={linea.id} value={linea.id}>{linea.name}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Modelo (Año) */}
                            <div>
                                <label htmlFor="modelo" className="block text-sm font-medium text-gray-700">Modelo (Año) de tu vehículo</label>
                                <select
                                    id="modelo"
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    value={modelo}
                                    onChange={(e) => setModelo(e.target.value)}
                                >
                                    <option value="">Selecciona un modelo (Año)</option>
                                    {modelosDisponibles.map((modelo) => (
                                        <option key={modelo} value={modelo}>{modelo}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Motor */}
                            <div>
                                <label htmlFor="motor" className="block text-sm font-medium text-gray-700">Motor de tu vehículo</label>
                                <select
                                    id="motor"
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    value={motor}
                                    onChange={(e) => setMotor(e.target.value)}
                                >
                                    <option value="">Selecciona un motor</option>
                                    {motores.map((motor) => (
                                        <option key={motor} value={motor}>{motor}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Otros campos como Km/MI y Aceite */}
                            <div>
                                <label htmlFor="kmMi" className="block text-sm font-medium text-gray-700">Kilometraje / Millaje</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        id="kmMi"
                                        className="mt-1 p-2 block w-3/4 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        value={kmMi}
                                        onChange={(e) => setKmMi(e.target.value)}
                                        placeholder="000,000"
                                    />
                                    <select
                                        id="unidadKmMi"
                                        className="mt-1 p-2 w-1/4 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        value={unidadKmMi}
                                        onChange={(e) => setUnidadKmMi(e.target.value)}
                                    >
                                        <option value="KM">KM</option>
                                        <option value="MI">MI</option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="aceite" className="block text-sm font-medium text-gray-700">Aceite a cotizar</label>
                                <select
                                    id="aceite"
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    value={aceite}
                                    onChange={(e) => setAceite(e.target.value)}
                                >
                                    <option value="">Selecciona un aceite</option>
                                    <option value="Castrol">Castrol (Alta calidad + larga vida)</option>
                                    <option value="Motul">Motul (Desempeño y duración light)</option>
                                </select>
                            </div>
                        </div>

                        {/* Botón Siguiente */}
                        <div className="text-center mt-6">
                            <button
                                onClick={handleNextStep}
                                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-all duration-300"
                            >
                                Siguiente
                            </button>
                        </div>
                    </div>
                )}

                {/* Segunda tarjeta */}
                {step === 2 && (
                    <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 ease-in-out duration-300">
                        <div className="flex items-center justify-between mb-6">
                            <button
                                onClick={handlePreviousStep}
                                className="text-blue-500 hover:text-blue-600 font-medium"
                            >
                                Regresar
                            </button>
                            <h2 className="text-3xl font-semibold text-center text-blue-900">Información general para cotización</h2>
                            <div className="w-8"></div>
                        </div>

                        <p className="text-gray-700 text-lg mb-4 text-center">
                            Para poder realizar tu cotización de la mejor manera y desde ya darte una atención completamente personalizada, ingresa la siguiente información
                        </p>
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="nombre" className="block text-sm font-medium text-gray-700">Ingresa tu nombre (1 nombre y 1 apellido)</label>
                                <input
                                    type="text"
                                    id="nombre"
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="numero" className="block text-sm font-medium text-gray-700">Ingresa tu número (Donde usas Whatsapp para enviarte tu cotización)</label>
                                <input
                                    type="text"
                                    id="numero"
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    value={numero}
                                    onChange={e => setNumero(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Botón "Cotizar" */}
                        <div className="text-center mt-6">
                            <button
                                onClick={handleGeneratePDF}
                                className="bg-green-500 text-white py-2 px-6 rounded-md hover:bg-green-600 transition-all duration-300"
                            >
                                Cotizar
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CotizadorForm;
