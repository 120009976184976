import jsPDF from "jspdf";
import 'jspdf-autotable';

class GeneratePDF {
    static generate({ cliente, nit, asesor, celular, marca, linea, modelo, motor, mayorServices = [], menorServices = [], extrasServices = [], total }) {
        const doc = new jsPDF();
        const logo = new Image();
        logo.src = '/logos/serbizcarlogo.png';

        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 40, 15);

            // Encabezado del documento con estilo moderno
            doc.setFontSize(16);
            doc.setTextColor(30, 58, 138); // blue-900
            doc.text('SERBIZCAR', 55, 18);
            doc.setFontSize(10);
            doc.text('Visítanos en www.serbizcar.com', 55, 23);

            doc.setFontSize(12);
            doc.text(`Ciudad de Guatemala, ${new Date().toLocaleDateString()}`, 140, 20);
            doc.text(`No. Cotización: 20250201`, 140, 26);

            // Caja de datos del cliente
            doc.roundedRect(10, 30, 190, 30, 3, 3);
            doc.setFontSize(11);
            doc.setTextColor(0, 0, 0);
            doc.text(`Cliente: ${cliente || '-'}`, 15, 38);
            doc.text(`NIT: ${nit || '-'}`, 15, 44);
            doc.text(`Asesor Técnico: ${asesor || '-'}`, 15, 50);
            doc.text(`Celular: ${celular || '-'}`, 15, 56);

            // Datos del vehículo
            const tableOptions = {
                theme: 'grid',
                headStyles: { fillColor: [30, 58, 138], textColor: [255, 255, 255], fontStyle: 'bold', fontSize: 10 },
                bodyStyles: { textColor: [0, 0, 0], fontSize: 9 },
                alternateRowStyles: { fillColor: [245, 245, 245] },
                margin: { top: 65 }
            };

            doc.autoTable({
                startY: 65,
                head: [['DATOS DEL VEHÍCULO', '']],
                body: [
                    ['Marca', marca || '-'],
                    ['Línea', linea || '-'],
                    ['Modelo', modelo || '-'],
                    ['Motor', motor || '-']
                ],
                ...tableOptions
            });

            // Función para calcular el total de los servicios
            const calculateTotal = (services) => {
                return services.reduce((acc, service) => {
                    const totalService = service[3] ? parseFloat(service[3].replace('Q.', '').trim()) : 0;
                    return isNaN(totalService) ? acc : acc + totalService;
                }, 0);
            };

            // Calculamos los totales de cada servicio
            let totalMayor = calculateTotal(mayorServices);
            let totalMenor = calculateTotal(menorServices);
            let totalExtras = calculateTotal(extrasServices);

            // Sumar todos los totales y el monto de mano de obra
            let totalGeneral = totalMayor + totalMenor + totalExtras + 150; // Agregamos el monto de "Trabajo a domicilio"

            // Tabla de Servicios Mayor
            if (mayorServices.length > 0) {
                doc.autoTable({
                    startY: doc.lastAutoTable.finalY + 5,
                    head: [['SERVICIOS A REALIZAR', 'CANTIDAD', 'PRECIO', 'TOTAL']],
                    body: mayorServices.length > 0 ? mayorServices : [['-', '-', '-', '-']],
                    ...tableOptions
                });
            }

            // Tabla de Servicios Menor
            if (menorServices.length > 0) {
                doc.autoTable({
                    startY: doc.lastAutoTable.finalY + 5,
                    head: [['SERVICIOS A REALIZAR', 'CANTIDAD', 'PRECIO', 'TOTAL']],
                    body: menorServices.length > 0 ? menorServices : [['-', '-', '-', '-']],
                    ...tableOptions
                });
            }

            // Tabla de Extras
            if (extrasServices.length > 0) {
                doc.autoTable({
                    startY: doc.lastAutoTable.finalY + 5,
                    head: [['ADICIONALES INCLUIDOS']],
                    body: extrasServices.length > 0 ? extrasServices : [['-', '-', '-', '-']],
                    ...tableOptions
                });
            }

            // Tabla de Mano de Obra
            const manoObraData = [
                ['Trabajo a domicilio', '1', 'Q150']
            ];

            doc.autoTable({
                startY: doc.lastAutoTable.finalY + 5,
                head: [['MANO DE OBRA', 'CANTIDAD', 'TOTAL']],
                body: manoObraData,
                theme: 'grid',
                headStyles: { fillColor: [30, 58, 138], textColor: [255, 255, 255], fontStyle: 'bold', fontSize: 10 },
                bodyStyles: { textColor: [0, 0, 0], fontSize: 9 },
                alternateRowStyles: { fillColor: [245, 245, 245] }
            });

            // Mostrar el Total General después de todas las tablas
            doc.setFontSize(12);
            doc.text(`Total General: Q.${totalGeneral.toFixed(2)}`, 140, doc.lastAutoTable.finalY + 10);

            // Calcular la posición para el texto adicional (debajo del total general)
            const textoAdicionalY = doc.lastAutoTable.finalY + 15;

            // Texto adicional al final
            doc.setFontSize(7);
            doc.setTextColor(100, 100, 100); // Letra gris clara
            const textoAdicional = [
                "Nuestros servicios son en la comodidad de tu hogar y con ello puedes tener la tranquilidad de estar en una zona segura y que tu vehículo estará resguardado.",
                "Josue 1.9"
            ];

            textoAdicional.forEach((linea, index) => {
                doc.text(linea, 15, textoAdicionalY + (index * 5));
            });

            // Guardar el archivo PDF
            doc.save(`Cotización_${cliente}.pdf`);

            // Registrar en localStorage
            const timestamp = new Date().toLocaleString(); // Fecha y hora actual
            const pdfRecord = { cliente, fecha: timestamp };

            let pdfHistory = JSON.parse(localStorage.getItem('pdfHistory')) || [];
            pdfHistory.push(pdfRecord);
            localStorage.setItem('pdfHistory', JSON.stringify(pdfHistory));
        };
    }
}

export default GeneratePDF;
