import React, { useState } from 'react';
import Modal from './Modal'; // Asumiendo que tienes un componente Modal genérico

const EditarModal = ({ item, onSave, onClose }) => {
    const [editedName, setEditedName] = useState(item.name);
    const [editedOilLiters, setEditedOilLiters] = useState(item.oilLiters || '');
    const [editedOilFilterPrice, setEditedOilFilterPrice] = useState(item.oilFilterPrice || '');
    const [editedAirFilterPrice, setEditedAirFilterPrice] = useState(item.airFilterPrice || '');
    const [editedCoolantPrice, setEditedCoolantPrice] = useState(item.coolantPrice || '');

    const handleSave = () => {
        onSave(item._id, {
            name: editedName,
            oilLiters: editedOilLiters,
            oilFilterPrice: editedOilFilterPrice,
            airFilterPrice: editedAirFilterPrice,
            coolantPrice: editedCoolantPrice,
        });
        onClose();
    };

    return (
        <Modal onClose={onClose}>
            <div className="p-4">
                <h2 className="text-lg font-bold mb-2">Editar {item.type === 'brand' ? 'Marca' : 'Modelo'}</h2>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder={`Nuevo nombre de ${item.type === 'brand' ? 'marca' : 'modelo'}`}
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                />
                {item.type === 'model' && (
                    <>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Litros de aceite"
                            value={editedOilLiters}
                            onChange={(e) => setEditedOilLiters(e.target.value)}
                        />
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Precio del filtro de aceite"
                            value={editedOilFilterPrice}
                            onChange={(e) => setEditedOilFilterPrice(e.target.value)}
                        />
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Precio del filtro de aire"
                            value={editedAirFilterPrice}
                            onChange={(e) => setEditedAirFilterPrice(e.target.value)}
                        />
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Precio del refrigerante"
                            value={editedCoolantPrice}
                            onChange={(e) => setEditedCoolantPrice(e.target.value)}
                        />
                    </>
                )}
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mr-2"
                        onClick={handleSave}
                    >
                        Guardar
                    </button>
                    <button
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EditarModal;
