import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
        setIsDropdownOpen(false);
    };

    return (
        <nav className="relative">
            <div className="max-w-screen-xl w-full flex flex-wrap justify-between mx-auto p-4 relative">
                <Link to="/" className="flex items-center">
                    <img src="/logos/serbizcarlogo.png" className="h-8 mr-2" alt="Serbizcar Logo" />
                    <span className="text-2xl font-semibold whitespace-nowrap text-gray-800" style={{ color: '#132C56' }}>Serbizcar</span>
                </Link>
                <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-blue-800 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-dropdown" aria-expanded={isOpen}>
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
                <div className={`w-full md:block md:w-auto ${isOpen ? '' : 'hidden'}`} id="navbar-dropdown">
                    <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-white md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
                        <li>
                            <Link to="/quote" onClick={closeMenu} className="block py-2 px-3 rounded hover:bg-gray-200 md:hover:bg-gray-100 md:border-0 md:hover:text-yellow-600 md:p-0" style={{ color: '#F5A727' }}>Cotiza tu servicio</Link>
                        </li>
                        <li className="relative">
                            <button onClick={toggleDropdown} className="flex items-center justify-between w-full py-2 px-3 rounded hover:bg-gray-200 md:hover:bg-gray-100 md:border-0 md:hover:text-yellow-600 md:p-0 md:w-auto" style={{ color: '#F5A727' }}>
                                Servicios
                                <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                                </svg>
                            </button>
                            {/* Dropdown menu */}
                            <div className={`absolute md:absolute top-full left-0 mt-2 z-10 ${isDropdownOpen ? '' : 'hidden'} font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}>
                                <ul className="py-2 text-sm" style={{ color: '#F5A727' }}>
                                    <li>
                                        <Link to="/ourServices" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-200">Nuestros servicios</Link>
                                    </li>
                                    <li>
                                        <Link to="/quote" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-200">Cotiza</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link to="/parkinglots" onClick={closeMenu} className="block py-2 px-3 rounded hover:bg-gray-200 md:hover:bg-gray-100 md:border-0 md:hover:text-yellow-600 md:p-0" style={{ color: '#F5A727' }}>Parqueos Asociados</Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={closeMenu} className="block py-2 px-3 rounded hover:bg-gray-200 md:hover:bg-gray-100 md:border-0 md:hover:text-yellow-600 md:p-0" style={{ color: '#F5A727' }}>Quienes somos</Link>
                        </li>
                        <li>
                            <Link to="/contact" onClick={closeMenu} className="block py-2 px-3 rounded hover:bg-gray-200 md:hover:bg-gray-100 md:border-0 md:hover:text-yellow-600 md:p-0" style={{ color: '#F5A727' }}>Contacto</Link>
                        </li>
                        {/* New link for "Nuestras empresas" */}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
