import React from 'react';

const Companies = () => {
    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4 flex flex-col items-center">
            <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Nuestras Empresas</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center items-start">
                {/* Tarjeta SerbizCar */}
                <div className="flex flex-col md:flex-row items-center p-4 rounded-lg bg-white shadow-md text-left">
                    <div className="md:w-1/3 md:mr-4 mb-4 md:mb-0 flex justify-center md:justify-start">
                        <img
                            src="/logos/serbizcarlogo.png"
                            alt="SerbizCar Logo"
                            className="w-20 h-auto md:w-32 max-w-full"
                        />
                    </div>
                    <div className="md:w-2/3">
                        <h3 className="text-xl font-semibold text-blue-900 mt-0 mb-2">Serbizcar</h3>
                        <p className="text-gray-700 md:text-base">
                            SerbizCar es una empresa dedicada al mantenimiento integral de vehículos. Nuestro compromiso es ofrecer servicios de alta calidad que garanticen la seguridad y el rendimiento de tu automóvil.<br /><br /><br />
                            Nuestro sitio: <a href="https://serbizcar.com" className="text-blue-600 hover:underline">serbizcar.com</a>
                        </p>
                    </div>
                </div>

                {/* Tarjeta BISA Mensajería */}
                <div className="flex flex-col md:flex-row items-center p-4 rounded-lg bg-white shadow-md text-left">
                    <div className="md:w-1/3 md:mr-4 mb-4 md:mb-0 flex justify-center md:justify-start">
                        <img
                            src="/logos/bisalogo.png"
                            alt="BISA Logo"
                            className="w-20 h-auto md:w-32 max-w-full"
                        />
                    </div>
                    <div className="md:w-2/3">
                        <h3 className="text-xl font-semibold text-blue-900 mt-0 mb-2">BISA Mensajería</h3>
                        <p className="text-gray-700 md:text-base">
                            Empresa de mensajería dedicada a la entrega rápida y eficiente de paquetes y documentos. Nuestro servicio garantiza la puntualidad y la seguridad en la distribución de tus envíos, utilizando tecnología avanzada para un seguimiento en tiempo real.<br /><br />
                            Nuestro sitio: <a href="https://bisa.serbizcar.com" className="text-blue-600 hover:underline">bisa.serbizcar.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Companies;
