import React, { useState, useEffect } from 'react';
import CotizadorService from "../../services/CotizadorService";

const ScheduleServiceModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        date: '',
        brand: '',
        model: '',
        year: '',
        engine: '',
    });
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);

    // Lista de años de 1970 a 2025
    const years = Array.from({ length: 2025 - 1970 + 1 }, (_, i) => 1970 + i);
    // Lista de motores
    const engines = [
        '800cc', '900cc', '1.0', '1.2', '1.4', '1.5', '1.6', '1.8', '2.0',
        '2.3', '2.5', '2.8', '3.0', '3.5', '4.0'
    ];

    // Fetch brands on component mount
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const brandsData = await CotizadorService.getBrands();
                setBrands(brandsData); // Ensure the API returns `{ _id, name }`
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);

    // Handle brand selection and fetch models
    const handleBrandChange = async (e) => {
        const selectedBrandId = e.target.value; // Get selected brand ID
        setFormData({ ...formData, brand: selectedBrandId, model: '' });

        try {
            const modelsData = await CotizadorService.getModelsByBrandId(selectedBrandId);
            setModels(modelsData); // Assume the API returns models `{ _id, name }`
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);

        // Implement API call or additional functionality here
        onClose(); // Close the modal
    };

    return isOpen ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
                <h2 className="text-2xl font-bold text-blue-900 mb-4 text-center">
                    Programar Servicio
                </h2>
                <form onSubmit={handleSubmit}>
                    {/* Fecha */}
                    <div className="mb-6">
                        <label className="block text-gray-700 font-medium mb-2">Fecha</label>
                        <input
                            type="date"
                            name="date"
                            value={formData.date}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={handleInputChange}
                            className="w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                            required
                        />
                    </div>


                    {/* Marca */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Marca de vehículo</label>
                        <select
                            name="brand"
                            value={formData.brand}
                            onChange={handleBrandChange}
                            className="w-full p-2 border rounded"
                            required
                        >
                            <option value="">Selecciona una marca</option>
                            {brands.map((brand) => (
                                <option key={brand._id} value={brand._id}>
                                    {brand.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Modelo */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Modelo de vehículo</label>
                        <select
                            name="model"
                            value={formData.model}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                        >
                            <option value="">Selecciona un modelo</option>
                            {models.map((model) => (
                                <option key={model._id} value={model._id}>
                                    {model.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Año y Motor en la misma fila */}
                    <div className="mb-4 grid grid-cols-2 gap-4">
                        {/* Año */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">Año de vehículo</label>
                            <select
                                name="year"
                                value={formData.year}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded"
                                required
                            >
                                <option value="">Selecciona un año</option>
                                {years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Motor */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">Motor</label>
                            <select
                                name="engine"
                                value={formData.engine}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded"
                                required
                            >
                                <option value="">Selecciona un motor</option>
                                {engines.map((engine) => (
                                    <option key={engine} value={engine}>
                                        {engine}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white py-2 px-4 rounded mr-2 hover:bg-gray-600"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Programar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    ) : null;
};

export default ScheduleServiceModal;
