import React, { useState, useEffect } from 'react';
import ParkingService from '../../services/ParkingService';
import LocationService from '../../services/LocationService';

const AdminParkingLots = () => {
    const [parkingLots, setParkingLots] = useState([]);
    const [formData, setFormData] = useState({
        id: null,
        image: '',
        name: '',
        department: '',
        municipality: '',
        zone: '',
        address: '',
    });
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [zones, setZones] = useState([]);

    useEffect(() => {
        const fetchParkingLots = async () => {
            try {
                const data = await ParkingService.getAllParkingLots();
                setParkingLots(data);
            } catch (err) {
                console.error('Error fetching parking lots:', err);
                setError('Error al cargar los parqueos.');
            } finally {
                setLoading(false);
            }
        };

        const fetchDepartments = async () => {
            try {
                const data = await LocationService.getDepartments();
                setDepartments(data);
            } catch (err) {
                console.error('Error fetching departments:', err);
                alert('Error al cargar los departamentos.');
            }
        };

        fetchParkingLots();
        fetchDepartments();
    }, []);

    const handleDepartmentChange = async (e) => {
        const departmentId = e.target.value;
        setFormData({ ...formData, department: departmentId, municipality: '', zone: '', address: '' });
        try {
            const data = await LocationService.getMunicipalities(departmentId);
            setMunicipalities(data);
            setZones([]);
        } catch (err) {
            console.error('Error fetching municipalities:', err);
        }
    };

    const handleMunicipalityChange = async (e) => {
        const municipalityId = e.target.value;
        setFormData({ ...formData, municipality: municipalityId, zone: '', address: '' });
        try {
            const data = await LocationService.getZones(municipalityId);
            setZones(data);
        } catch (err) {
            console.error('Error fetching zones:', err);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { department, municipality, zone, address, ...rest } = formData;
            const fullAddress = `${department}, ${municipality}, ${zone}, ${address}`;
            const payload = { ...rest, address: fullAddress };

            if (isEditing) {
                await ParkingService.updateParkingLot(formData.id, payload);
                setParkingLots((prev) =>
                    prev.map((lot) => (lot.id === formData.id ? { ...lot, ...payload } : lot))
                );
                setIsEditing(false);
            } else {
                const newLot = await ParkingService.createParkingLot(payload);
                setParkingLots((prev) => [...prev, newLot]);
            }

            setFormData({ id: null, image: '', name: '', department: '', municipality: '', zone: '', address: '' });
        } catch (err) {
            console.error('Error saving parking lot:', err);
            alert('Error al guardar el parqueo. Intenta nuevamente.');
        }
    };

    const handleEdit = (id) => {
        const lotToEdit = parkingLots.find((lot) => lot.id === id);
        if (lotToEdit) {
            const [department, municipality, zone, address] = lotToEdit.address.split(', ');
            setFormData({
                id: lotToEdit.id,
                image: lotToEdit.image,
                name: lotToEdit.name,
                address: lotToEdit.address,
                department,
                municipality,
                zone,
            });
            setIsEditing(true);
        }
    };

    const handleDelete = async (id) => {
        try {
            await ParkingService.deleteParkingLot(id);
            setParkingLots((prev) => prev.filter((lot) => lot.id !== id));
        } catch (err) {
            console.error('Error deleting parking lot:', err);
            alert('Error al eliminar el parqueo. Intenta nuevamente.');
        }
    };

    if (loading) {
        return <div className="text-center text-blue-900">Cargando parqueos...</div>;
    }

    if (error) {
        return <div className="text-center text-red-600">{error}</div>;
    }

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4 flex flex-col items-center">
            <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Administrador de Parqueos</h1>

            {/* Tabla para mostrar parqueos existentes */}
            <div className="overflow-x-auto w-full mb-8">
                <table className="table-auto w-full bg-white shadow-md rounded-lg">
                    <thead>
                    <tr className="bg-blue-600 text-white">
                        <th className="px-4 py-2">ID</th>
                        <th className="px-4 py-2">Nombre</th>
                        <th className="px-4 py-2">Imagen</th>
                        <th className="px-4 py-2">Dirección</th>
                        <th className="px-4 py-2">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {parkingLots.map((lot) => (
                        <tr key={lot.id} className="border-t">
                            <td className="px-4 py-2">{lot.id}</td>
                            <td className="px-4 py-2">{lot.name}</td>
                            <td className="px-4 py-2">
                                <img src={lot.image} alt={lot.name} className="h-16 w-16 object-cover rounded" />
                            </td>
                            <td className="px-4 py-2">{lot.address}</td>
                            <td className="px-4 py-2 flex justify-center">
                                <button
                                    onClick={() => handleEdit(lot.id)}
                                    className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                                >
                                    Editar
                                </button>
                                <button
                                    onClick={() => handleDelete(lot.id)}
                                    className="bg-red-500 text-white px-4 py-2 rounded"
                                >
                                    Eliminar
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Formulario para agregar/editar parqueo */}
            <form onSubmit={handleSubmit} className="w-full max-w-md bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold text-blue-900 mb-4">
                    {isEditing ? 'Editar Parqueo' : 'Agregar Parqueo'}
                </h2>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-blue-900">
                        Nombre de Parqueo
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="image" className="block text-sm font-medium text-blue-900">
                        Imagen
                    </label>
                    <input
                        type="text"
                        id="image"
                        name="image"
                        value={formData.image}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                </div>
                {/* Dirección */}
                <div className="mb-4">
                    <label htmlFor="address" className="block text-sm font-medium text-blue-900">
                        Dirección
                    </label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                {/* Departamento */}
                <div className="mb-4">
                    <label htmlFor="department" className="block text-sm font-medium text-blue-900">
                        Departamento
                    </label>
                    <select
                        id="department"
                        name="department"
                        value={formData.department}
                        onChange={handleDepartmentChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Selecciona un departamento</option>
                        {departments.map((dept) => (
                            <option key={dept.id} value={dept.id}>
                                {dept.name}
                            </option>
                        ))}
                    </select>
                </div>
                {/* Municipio */}
                <div className="mb-4">
                    <label htmlFor="municipality" className="block text-sm font-medium text-blue-900">
                        Municipio
                    </label>
                    <select
                        id="municipality"
                        name="municipality"
                        value={formData.municipality}
                        onChange={handleMunicipalityChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Selecciona un municipio</option>
                        {municipalities.map((municipality) => (
                            <option key={municipality.id} value={municipality.id}>
                                {municipality.name}
                            </option>
                        ))}
                    </select>
                </div>
                {/* Zona */}
                <div className="mb-4">
                    <label htmlFor="zone" className="block text-sm font-medium text-blue-900">
                        Zona
                    </label>
                    <select
                        id="zone"
                        name="zone"
                        value={formData.zone}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Selecciona una zona</option>
                        {zones.map((zone) => (
                            <option key={zone.id} value={zone.id}>
                                {zone.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-center mt-6">
                    <button
                        type="submit"
                        className="bg-blue-600 text-white px-6 py-2 rounded"
                    >
                        {isEditing ? 'Actualizar' : 'Agregar'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AdminParkingLots;
