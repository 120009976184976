import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./components/sections/Navbar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Footer from "./components/sections/Footer";
import CotizadorForm from "./pages/CotizadorForm";
import About from "./pages/About";
import AgendaCalendar from "./components/sections/AgendaCalendar";
import OurServices from "./pages/OurServices";
import Companies from "./pages/Companies";
import CotizadorAdmin from "./pages/admin/CotizadorAdmin";
import ParkingLots from "./pages/ParkingLots";
import AdminParkingLots from "./pages/admin/AdminParkingLots";
import ADM from "./pages/ADM";
import CotizadorMaster from "./pages/admin/CotizadorMaster";


const App = () => {
  return (
      <Router>
          <Navbar />
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/quote" element={<CotizadorForm />} />
              <Route path="/about" element={<About />} />
              <Route path="/calendar" element={<AgendaCalendar />} />
              <Route path="/ourServices" element={<OurServices />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/quote/admin" element={<CotizadorAdmin />} />
              <Route path="/parkinglots" element={<ParkingLots />} />
              <Route path="/admin/parkinglots" element={<AdminParkingLots />}/>
              <Route path="/admin/allSites" element={<ADM />}/>
              <Route path="/admin/cotizadorMaster" element={<CotizadorMaster />}/>
          </Routes>
          <Footer />
      </Router>
  );
}

export default App;
