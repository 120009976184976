import React, { useState } from 'react';
import AgendaCalendar from './AgendaCalendar';

const AgendarComponente = ({ appointments, onClose }) => {
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    const handleCancelarAgenda = () => {
        onClose(); // Función para cerrar el modal
    };

    const handleAgendar = () => {
        console.log('Agendar cita:', selectedAppointment);
        onClose();
    };

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center">
            <div className="relative bg-white w-full max-w-md p-8 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Selecciona una fecha y hora</h2>
                <AgendaCalendar
                    appointments={appointments}
                    onAppointmentChange={() => {}}
                    setSelectedAppointment={setSelectedAppointment}
                />
                {selectedAppointment && (
                    <div className="flex justify-center mt-6">
                        <button
                            type="button"
                            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mr-4"
                            onClick={handleAgendar}
                        >
                            Agendar
                        </button>
                    </div>
                )}
                <div className="flex justify-center mt-4">
                    <button
                        type="button"
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                        onClick={handleCancelarAgenda}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AgendarComponente;
