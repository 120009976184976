import React, { useState, useEffect } from 'react';
import ScheduleServiceModal from "../components/modals/ScheduleServiceModal";
import ParkingService from "../services/ParkingService";
import AgendarComponente from '../components/sections/AgendarComponente'; // Asegúrate de importar el componente

const ParkingLots = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedParkingLot, setSelectedParkingLot] = useState(null);
    const [parkingLots, setParkingLots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAgendarOpen, setIsAgendarOpen] = useState(false); // Estado para abrir el modal Agendar
    const [appointments, setAppointments] = useState([]); // Datos de citas (puedes obtenerlos de una API o definirlos aquí)

    useEffect(() => {
        const fetchParkingLots = async () => {
            try {
                const data = await ParkingService.getAllParkingLots();
                setParkingLots(data);
            } catch (err) {
                setError('Error loading parking lots.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchParkingLots();
    }, []);

    // Función para abrir el modal de "Programar Servicio"
    const handleOpenModal = (parkingLot) => {
        setSelectedParkingLot(parkingLot);
        setIsModalOpen(true);
    };

    // Función para abrir el modal de "Agendar cita"
    const handleOpenAgendar = () => {
        setIsAgendarOpen(true);
    };

    // Función para cerrar el modal de "Programar Servicio"
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedParkingLot(null);
    };

    // Función para cerrar el modal de "Agendar cita"
    const handleCloseAgendar = () => {
        setIsAgendarOpen(false);
    };

    if (loading) {
        return <div className="text-center text-blue-900">Loading parking lots...</div>;
    }

    if (error) {
        return <div className="text-center text-red-600">{error}</div>;
    }

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4 flex flex-col items-center">
            <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Parqueos asociados</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-center items-center">
                {parkingLots.map((lot, index) => (
                    <div
                        key={lot._id || index}
                        className="flex flex-col items-center justify-center p-4 rounded-lg bg-white shadow-md text-center"
                    >
                        <img
                            src={lot.image}
                            alt={lot.name}
                            className="w-20 h-20 object-cover rounded-md mb-4"
                        />
                        <h3 className="text-xl font-semibold text-blue-900 mt-4 mb-2">{lot.name}</h3>
                        <p className="text-gray-700 mb-4">{lot.address}</p>
                        <button
                            onClick={() => {
                                handleOpenAgendar(); // Abre el modal Agendar
                            }}
                            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Programar servicio aquí
                        </button>
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <ScheduleServiceModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    parkingLot={selectedParkingLot}
                />
            )}
            {isAgendarOpen && (
                <AgendarComponente
                    appointments={appointments}
                    onClose={handleCloseAgendar} // Cerrar modal Agendar
                />
            )}
        </div>
    );
};

export default ParkingLots;
